import ImgEightySix from '../assets/images/gallery/image-86.jpeg';
import ImgEightySeven from '../assets/images/gallery/image-87.jpeg';
import ImgEightyEight from '../assets/images/gallery/image-88.jpeg';
import ImgEightyNine from '../assets/images/gallery/image-89.jpeg';
import ImgNinety from '../assets/images/gallery/image-90.jpeg';
import ImgNinetyOne from '../assets/images/gallery/image-91.jpeg';
import ImgNinetyTwo from '../assets/images/gallery/image-92.jpeg';
import ImgNinetyFour from '../assets/images/gallery/image-94.jpeg';
import ImgNinetyFive from '../assets/images/gallery/image-95.jpeg';
import ImgNinetySix from '../assets/images/gallery/image-96.jpeg';
import ImgNinetySeven from '../assets/images/gallery/image-97.jpeg';
import ImgSixtySix from '../assets/images/gallery/image-66.jpeg';
import ImgSixtySeven from '../assets/images/gallery/image-67.jpeg';
import ImgSixtyEight from '../assets/images/gallery/image-68.jpeg';
import ImgSixtyNine from '../assets/images/gallery/image-69.jpeg';
import ImgSeventy from '../assets/images/gallery/image-70.jpeg';
import ImgSeventyOne from '../assets/images/gallery/image-71.jpeg';
import ImgSeventyTwo from '../assets/images/gallery/image-72.jpeg';
import ImgSeventyThree from '../assets/images/gallery/image-73.jpeg';
import ImgSeventyFour from '../assets/images/gallery/image-74.jpeg';
import ImgSeventyFive from '../assets/images/gallery/image-75.jpeg';
import ImgSeventySix from '../assets/images/gallery/image-76.jpeg';
import ImgSeventySeven from '../assets/images/gallery/image-77.jpeg';
import ImgSeventyEight from '../assets/images/gallery/image-78.jpeg';
import ImgSeventyNine from '../assets/images/gallery/image-79.jpeg';
import ImgEighty from '../assets/images/gallery/image-80.jpeg';
import ImgEightyOne from '../assets/images/gallery/image-81.jpeg';
import ImgEightyTwo from '../assets/images/gallery/image-82.jpeg';
import ImgEightyThree from '../assets/images/gallery/image-83.jpeg';
import ImgEightyFour from '../assets/images/gallery/image-84.jpeg';
import ImgEightyFive from '../assets/images/gallery/image-85.jpeg';
import ImgOne from '../assets/images/gallery/image-1.jpeg';
import ImgTwo from '../assets/images/gallery/image-2.jpeg';
import ImgThree from '../assets/images/gallery/image-3.jpeg';
import ImgFour from '../assets/images/gallery/image-4.jpeg';
import ImgFive from '../assets/images/gallery/image-5.jpeg';
import ImgSix from '../assets/images/gallery/image-6.jpeg';
import ImgSeven from '../assets/images/gallery/image-7.jpeg';
import ImgEight from '../assets/images/gallery/image-8.jpeg';
import ImgNine from '../assets/images/gallery/image-9.jpeg';
import ImgEleven from '../assets/images/gallery/image-11.jpeg';
import ImgTwelve from '../assets/images/gallery/image-12.jpeg';
import ImgThirteen from '../assets/images/gallery/image-13.jpeg';
import ImgFourteen from '../assets/images/gallery/image-14.jpeg';
import ImgFifteen from '../assets/images/gallery/image-15.jpeg';
import ImgSixteen from '../assets/images/gallery/image-16.jpeg';
import ImgSeventeen from '../assets/images/gallery/image-17.jpeg';
import ImgEighteen from '../assets/images/gallery/image-18.jpeg';
import ImgNineteen from '../assets/images/gallery/image-19.jpeg';
import ImgTwenty from '../assets/images/gallery/image-20.jpeg';
import ImgTwentyOne from '../assets/images/gallery/image-21.jpeg';
import ImgTwentyTwo from '../assets/images/gallery/image-22.jpeg';
import ImgTwentyThree from '../assets/images/gallery/image-23.jpeg';
import ImgTwentyFour from '../assets/images/gallery/image-24.jpeg';
import ImgTwentyFive from '../assets/images/gallery/image-25.jpeg';
import ImgTwentySix from '../assets/images/gallery/image-26.jpeg';
import ImgTwentySeven from '../assets/images/gallery/image-27.jpeg';
import ImgTwentyEight from '../assets/images/gallery/image-28.jpeg';
import ImgTwentyNine from '../assets/images/gallery/image-29.jpeg';
import ImgThirty from '../assets/images/gallery/image-30.jpeg';
import ImgThirtyOne from '../assets/images/gallery/image-31.jpeg';
import ImgThirtyTwo from '../assets/images/gallery/image-32.jpeg';
import ImgThirtyThree from '../assets/images/gallery/image-33.jpeg';
import ImgThirtyFour from '../assets/images/gallery/image-34.jpeg';
import ImgThirtyFive from '../assets/images/gallery/image-35.jpeg';
import ImgThirtySix from '../assets/images/gallery/image-36.jpeg';
import ImgThirtySeven from '../assets/images/gallery/image-37.jpeg';
import ImgThirtyEight from '../assets/images/gallery/image-38.jpeg';
import ImgThirtyNine from '../assets/images/gallery/image-39.jpeg';
import ImgFourty from '../assets/images/gallery/image-40.jpeg';
import ImgFourtyOne from '../assets/images/gallery/image-41.jpeg';
import ImgFourtyTwo from '../assets/images/gallery/image-42.jpeg';
import ImgFourtyThree from '../assets/images/gallery/image-43.jpeg';
import ImgFourtyFour from '../assets/images/gallery/image-44.jpeg';
import ImgFourtyFive from '../assets/images/gallery/image-45.jpeg';
import ImgFourtySix from '../assets/images/gallery/image-46.jpeg';
import ImgFourtySeven from '../assets/images/gallery/image-47.jpeg';
import ImgFourtyEight from '../assets/images/gallery/image-48.jpeg';
import ImgFourtyNine from '../assets/images/gallery/image-49.jpeg';
import ImgFifty from '../assets/images/gallery/image-50.jpeg';
import ImgFiftyOne from '../assets/images/gallery/image-51.jpeg';
import ImgFiftyTwo from '../assets/images/gallery/image-52.jpeg';
import ImgFiftyThree from '../assets/images/gallery/image-53.jpeg';
import ImgFiftyFour from '../assets/images/gallery/image-54.jpeg';
import ImgFiftyFive from '../assets/images/gallery/image-55.jpeg';
import ImgFiftySix from '../assets/images/gallery/image-56.jpeg';
import ImgFiftySeven from '../assets/images/gallery/image-57.jpeg';
import ImgFiftyEight from '../assets/images/gallery/image-58.jpeg';
import ImgFiftyNine from '../assets/images/gallery/image-59.jpeg';
import ImgSixty from '../assets/images/gallery/image-60.jpeg';
import ImgSixtyOne from '../assets/images/gallery/image-61.jpeg';
import ImgSixtyTwo from '../assets/images/gallery/image-62.jpeg';
import ImgSixtyThree from '../assets/images/gallery/image-63.jpeg';
import ImgSixtyFour from '../assets/images/gallery/image-64.jpeg';
import ImgSixtyFive from '../assets/images/gallery/image-65.jpeg';

const GalleryOne = [
  { id: 86, src: ImgEightySix, alt: '' },
  { id: 87, src: ImgEightySeven, alt: '' },
  { id: 88, src: ImgEightyEight, alt: '' },
  { id: 89, src: ImgEightyNine, alt: '' },
  { id: 90, src: ImgNinety, alt: '' },
  { id: 91, src: ImgNinetyOne, alt: '' },
  { id: 92, src: ImgNinetyTwo, alt: '' },
  { id: 94, src: ImgNinetyFour, alt: '' },
  { id: 95, src: ImgNinetyFive, alt: '' },
  { id: 96, src: ImgNinetySix, alt: '' },
  { id: 97, src: ImgNinetySeven, alt: '' },
  { id: 66, src: ImgSixtySix, alt: '' },
  { id: 67, src: ImgSixtySeven, alt: '' },
  { id: 68, src: ImgSixtyEight, alt: '' },
  { id: 69, src: ImgSixtyNine, alt: '' },
  { id: 70, src: ImgSeventy, alt: '' },
  { id: 71, src: ImgSeventyOne, alt: '' },
  { id: 72, src: ImgSeventyTwo, alt: '' },
  { id: 73, src: ImgSeventyThree, alt: '' },
  { id: 74, src: ImgSeventyFour, alt: '' },
  { id: 75, src: ImgSeventyFive, alt: '' },
  { id: 76, src: ImgSeventySix, alt: '' },
  { id: 77, src: ImgSeventySeven, alt: '' },
  { id: 78, src: ImgSeventyEight, alt: '' },
  { id: 79, src: ImgSeventyNine, alt: '' },
  { id: 80, src: ImgEighty, alt: '' },
  { id: 81, src: ImgEightyOne, alt: '' },
  { id: 82, src: ImgEightyTwo, alt: '' },
  { id: 83, src: ImgEightyThree, alt: '' },
  { id: 84, src: ImgEightyFour, alt: '' },
  { id: 85, src: ImgEightyFive, alt: '' },
  { id: 1, src: ImgOne, alt: '' },
  { id: 2, src: ImgTwo, alt: '' },
  { id: 3, src: ImgThree, alt: '' },
  { id: 4, src: ImgFour, alt: '' },
  { id: 5, src: ImgFive, alt: '' },
  { id: 6, src: ImgSix, alt: '' },
  { id: 7, src: ImgSeven, alt: '' },
  { id: 8, src: ImgEight, alt: '' },
  { id: 9, src: ImgNine, alt: '' },
  { id: 11, src: ImgEleven, alt: '' },
  { id: 12, src: ImgTwelve, alt: '' },
  { id: 13, src: ImgThirteen, alt: '' },
  { id: 14, src: ImgFourteen, alt: '' },
  { id: 15, src: ImgFifteen, alt: '' },
  { id: 16, src: ImgSixteen, alt: '' },
  { id: 17, src: ImgSeventeen, alt: '' },
  { id: 18, src: ImgEighteen, alt: '' },
  { id: 19, src: ImgNineteen, alt: '' },
  { id: 20, src: ImgTwenty, alt: '' },
  { id: 21, src: ImgTwentyOne, alt: '' },
  { id: 22, src: ImgTwentyTwo, alt: '' },
  { id: 23, src: ImgTwentyThree, alt: '' },
  { id: 24, src: ImgTwentyFour, alt: '' },
  { id: 25, src: ImgTwentyFive, alt: '' },
  { id: 26, src: ImgTwentySix, alt: '' },
  { id: 27, src: ImgTwentySeven, alt: '' },
  { id: 28, src: ImgTwentyEight, alt: '' },
  { id: 29, src: ImgTwentyNine, alt: '' },
  { id: 30, src: ImgThirty, alt: '' },
  { id: 31, src: ImgThirtyOne, alt: '' },
  { id: 32, src: ImgThirtyTwo, alt: '' },
  { id: 33, src: ImgThirtyThree, alt: '' },
  { id: 34, src: ImgThirtyFour, alt: '' },
  { id: 35, src: ImgThirtyFive, alt: '' },
  { id: 36, src: ImgThirtySix, alt: '' },
  { id: 37, src: ImgThirtySeven, alt: '' },
  { id: 38, src: ImgThirtyEight, alt: '' },
  { id: 39, src: ImgThirtyNine, alt: '' },
  { id: 40, src: ImgFourty, alt: '' },
  { id: 41, src: ImgFourtyOne, alt: '' },
  { id: 42, src: ImgFourtyTwo, alt: '' },
  { id: 43, src: ImgFourtyThree, alt: '' },
  { id: 44, src: ImgFourtyFour, alt: '' },
  { id: 45, src: ImgFourtyFive, alt: '' },
  { id: 46, src: ImgFourtySix, alt: '' },
  { id: 47, src: ImgFourtySeven, alt: '' },
  { id: 48, src: ImgFourtyEight, alt: '' },
  { id: 49, src: ImgFourtyNine, alt: '' },
  { id: 50, src: ImgFifty, alt: '' },
  { id: 51, src: ImgFiftyOne, alt: '' },
  { id: 52, src: ImgFiftyTwo, alt: '' },
  { id: 53, src: ImgFiftyThree, alt: '' },
  { id: 54, src: ImgFiftyFour, alt: '' },
  { id: 55, src: ImgFiftyFive, alt: '' },
  { id: 56, src: ImgFiftySix, alt: '' },
  { id: 57, src: ImgFiftySeven, alt: '' },
  { id: 58, src: ImgFiftyEight, alt: '' },
  { id: 59, src: ImgFiftyNine, alt: '' },
  { id: 60, src: ImgSixty, alt: '' },
  { id: 61, src: ImgSixtyOne, alt: '' },
  { id: 62, src: ImgSixtyTwo, alt: '' },
  { id: 63, src: ImgSixtyThree, alt: '' },
  { id: 64, src: ImgSixtyFour, alt: '' },
  { id: 65, src: ImgSixtyFive, alt: '' },
];
export default GalleryOne;
